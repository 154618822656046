<template>
	<CustomTable
		:recent-filter="recentFilters"
		:total-pages="totalPages"
		:total-items="totalItems"
		:label-search="$t('TransactionFilters.search')"
		:label-date="$t('TransactionFilters.created')"
		:show-total-amount="true"
		:total-amount="totalAmount"
		@search="search"
	>
		<template #headers>
			<tr>
				<th
					v-for="(header, index1) in headers"
					:key="`${header.title}-${index1}`"
					scope="col"
					:class="`${header.class}`"
				>
					{{ header.title }}
				</th>
			</tr>
		</template>
		<template #body>
			<template v-if="transactions && transactions.length">
				<tr v-for="(item, index2) in transactions" :key="`${item.id}-${index2}`">
					<td scope="row" class="text-break text-center">
						{{ item.systemName }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ item.email }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ item.id }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ Number(item.total) }}
					</td>
					<td scope="row" class="text-break text-center fit">
						{{ convertUTCtoDateVN(item.time) }}
					</td>
				</tr>
			</template>
			<template v-else>
				<tr class="text-center">
					<td :colspan="headers.length" class="fit">
						{{ $t("Table.NoData") }}
					</td>
				</tr>
			</template>
		</template>
	</CustomTable>
</template>
<script>
import { createNamespacedHelpers } from "vuex"
import { GET_TRANSACTIONS } from "../store/action-types"
import { RESET_STATE } from "../store/mutation-types"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("transaction")
export default {
	name: "TransactionManagement",
	data() {
		return {
			headers: [
				{
					title: this.$t("TransactionFieldTable.systemName"),
					class: "text-center",
				},
				{
					title: this.$t("TransactionFieldTable.email"),
					class: "text-nowrap title text-center",
				},
				{
					title: this.$t("TransactionFieldTable.orderId"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("TransactionFieldTable.amount"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("TransactionFieldTable.time"),
					class: "text-nowrap text-center",
				},
			],
		}
	},
	computed: {
		...mapState(["recentFilters", "transactions", "totalPages", "totalItems", "totalAmount"]),
	},
	beforeDestroy() {
		this.RESET_STATE()
	},
	methods: {
		...mapActions({ GET_TRANSACTIONS }),
		...mapMutations({ RESET_STATE }),
		async search(params) {
			await this.GET_TRANSACTIONS(params)
		},
	},
}
</script>
<style lang="scss"></style>
